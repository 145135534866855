
import "./Table.scss";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { getExternalSystemNameForUser } from "@/utils";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
import { tsService } from "@/shared/services/api/trouble-shooting";
import store from "@/store";
import moment from "moment";
import moment_tz from "moment-timezone";
import {
  SCHEDULE_TYPE,
  WORKITEM_JOB_TYPE,
  SUBMIT_WORKITEM_OBJECT,
} from "@/shared/interface/newItem";
import { SCHEDULE_STATUS } from "@/shared/interface/scheduledItem";
import {
  OnDemandTableHeader,
  ScheduledTableHeader,
  ScheduledSubTableHeader,
  PeriodicalSubTableEntry,
  WORKITEM_FILTER_CRITERIA,
} from "@/shared/interface/workItem";
import SubPeriodicalTable from "@/shared/components/ItemScheduledListComponent/SubPeriodicalTable.vue";
import DownloadReports from "../../ItemDetailComponent/DownloadReports/DownloadReports.vue";
import { EXECUTION_STATUS } from "@/shared/interface/workItemDetails";
import { Auth } from "@/shared/services/auth";
import { signalRService } from "@/shared/services/websocket";
import { SEARCH_ITEM_CRITERIA } from "../../../interface/searchCriteria";
import { triggerJob } from "../../../utils/triggerJob";

const _format = "YYYY-MM-DD HH:mm:ss";

@Component({
  name: "TableComponent",
  components: {
    SubPeriodicalTable,
    DownloadReports,
  },
})
export default class TableComponent extends Vue {
  @Prop({ default: [WORKITEM_JOB_TYPE.ondemand] }) workJobType;
  @Prop() searchCriteria: SEARCH_ITEM_CRITERIA;

  formTitle: any = [
    { label: "ID", model: "id", type: "input", span: 4 },
    { label: "SUMMARY", model: "summary", type: "input", span: 4 },
    { label: "PRODUCT", model: "product", type: "select", span: 4 },
    { label: "CONTENT", model: "content", type: "input", span: 4 },
    {
      label: "EXECUTION STATUS",
      model: "executionstatus",
      type: "select",
      span: 4,
    },
  ];
  formInline: WORKITEM_FILTER_CRITERIA = {};
  search: any = ["product", "executionStatus"];
  loading = false;
  pageSize = 10;
  currentPage = 0;
  offset = 0;
  total = 0;
  tableKey = "work-item-";
  summary = "";
  tableTitle: any = OnDemandTableHeader();
  tableData: any = [];
  timezoneSettings = {
    currentTimezone: moment_tz.tz.guess(),
    currentTimezoneOffset: Math.floor(moment().utcOffset() / 60),
  };
  jobTypeEnums = WORKITEM_JOB_TYPE;
  ScheduledSubTableHeader = ScheduledSubTableHeader();
  fromViewWorkItemEntry = PeriodicalSubTableEntry.ViewWorkItem;
  JOBRUNNING = EXECUTION_STATUS.running;
  cancelBtnObj = {};
  showDownloadDialog = false;
  activeDownloadItem = null;

  @Watch("$store.state.currentApp", { deep: true, immediate: true })
  onCurrentAppChange(): void {
    this.formInline = {};
    this.$refs.filterTable?.clearFilter();
  }

  @Watch("workJobType", { deep: true, immediate: true })
  toggleWorkJobType(val: Array<string>): void {
    this.formInline = {
      ...this.formInline,
      // product: Auth.getProductFilters().map((product) => product?.value),
    };

    if (val.includes(WORKITEM_JOB_TYPE.scheduled)) {
      this.tableTitle = ScheduledTableHeader();
    }

    this.searchData();
  }

  /**
   * The actions to be performed after the VUE instance is created
   * @returns void
   */
  created(): void {
    this.formTitle.forEach((item: any) => {
      if (this.search.indexOf(item.model) !== -1) {
        this.formInline[item.model] = [];
      } else {
        this.formInline[item.model] = "";
      }
    });
    this.formInline = {
      product: Auth.getProductFilters().map((product) => product?.value),
    };
  }

  isWorkItemExecutionHasFilePath(jobExecutions: any): boolean {
    let hasFilePath = false;
    if ((jobExecutions as any)[0]?.file_path) {
      hasFilePath = (jobExecutions as any)[0]?.file_path.length > 0;
      return hasFilePath;
    }
    return hasFilePath;
  }

  closeDownloadDialog(): void {
    this.showDownloadDialog = false;
    this.activeDownloadItem = null;
  }

  getReportsFilePathForExecutionId(): string[] {
    let filepathArr = [];
    if (this.activeDownloadItem) {
      filepathArr = (
        this.activeDownloadItem as any
      )?.cx_iops_agent_job_executions[0].file_path.split(";");
    }
    return filepathArr;
  }

  downloadReportForWorkItem(currentWorkItem: any): void {
    this.activeDownloadItem = currentWorkItem;
    this.showDownloadDialog = true;
  }

  /**
   * Skip to the Case Details page
   * @params {id}: case id
   * @param {execution_id}: execution_id
   * @returns void
   */
  gotoDetail(id: string, execution_id = ""): void {
    const targetRoute = `/detail?id=${id}&executionId=${execution_id}&jobType=${this.workJobType[0]}&newTab=true`;
    const route = this.$router.resolve({ path: targetRoute });
    window.open(route.href);
    // this["$router"].push(`/detail?id=${id}&execution_id=${execution_id}&jobType=${this.workJobType[0]}`)
  }

  /**
   * Retrieve data based on the current page number
   * @params {currentPage}: current page
   * @returns void
   */
  currentPageChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.offset = (currentPage - 1) * this.pageSize;
    this.getData();
  }

  /**
   * Fuzzy search, reset page number
   * @returns void
   */
  searchData(): void {
    this.currentPage = 0;
    this.total = 0;
    this.offset = 0;
    this.getData();
  }

  /**
   * get the owner id from search criteria/the store
   * @returns owner id
   */
  getOwnerId(): string[] | null {
    if (this.searchCriteria?.ownerType === "All") {
      let ownerID: string | undefined = this.searchCriteria?.userID;
      //When user types in external system name to search workitems
      const userIDNameStr = process.env.VUE_APP_USERID_SYSNAME_MAP;
      if (userIDNameStr) {
        const userIdSysNameMap = JSON.parse(userIDNameStr);
        const externalSystemIndex = userIdSysNameMap.findIndex(
          (item) =>
            item.externalSystemName.toLowerCase().trim() ===
            this.searchCriteria?.userID?.toLowerCase().trim()
        );
        if (externalSystemIndex !== -1)
          ownerID = userIdSysNameMap[externalSystemIndex].userId;
      }
      return ownerID ? [ownerID] : null;
    }
    return [this.$store.state.userId];
  }

  /**
   * get the runbook job id from search criteria
   * @returns job id
   */
  getJobId(): string[] | null {
    return this.searchCriteria?.runbookId.length > 0
      ? this.searchCriteria.runbookId
      : null;
  }

  /**
   * get the timestamp range
   * @returns timestamp string
   */
  getCreatedTimeRange(state: string): string {
    const createdTime =
      state === "start"
        ? this.searchCriteria?.createdTimeRange[0]
        : this.searchCriteria?.createdTimeRange[1];
    return moment.utc(createdTime).format();
  }

  // /**
  //  * get the externalSystem name for the userid if userid has external system mapping
  //  * @returns externalSystemName string
  //  */
  // getExternalSystemNameForUser(ownerId: string): string {
  //   const userIDNameStr = process.env.VUE_APP_USERID_SYSNAME_MAP;
  //   if (!userIDNameStr) return ownerId;
  //   const userIdSysNameMap = JSON.parse(userIDNameStr);
  //   const externalNameIndex = userIdSysNameMap.findIndex(
  //     (item) => item.userId === ownerId
  //   );
  //   return externalNameIndex !== -1
  //     ? userIdSysNameMap[externalNameIndex].externalSystemName
  //     : ownerId;
  // }

  /**
   * get data
   * @returns void
   */
  getData(): void {
    this.loading = true;
    for (let key in this.formInline) {
      if (this.formInline[key] !== null) {
        if (typeof this.formInline[key] === "string" && !this.formInline[key]) {
          delete this.formInline[key];
        }
        if (
          typeof this.formInline[key] === "object" &&
          this.formInline[key].length === 0
        ) {
          delete this.formInline[key];
        }
      }
    }
    if (this.summary) {
      this.formInline["summary"] = "%" + this.summary + "%";
    } else {
      delete this.formInline["summary"];
    }

    this.formInline = {
      ...this.formInline,
      JobType: this.workJobType,
      app_name: store.state.currentApp.name,
      offset: this.offset,
      pageSize: this.pageSize,
      owner: this.getOwnerId(),
      JobId: this.getJobId(),
      summary: this.searchCriteria?.summary
        ? "%" + this.searchCriteria.summary + "%"
        : this.formInline.summary,
      createdTimeRangeFrom: this.searchCriteria?.createdTimeRange
        ? this.getCreatedTimeRange("start")
        : null,
      createdTimeRangeTo: this.searchCriteria?.createdTimeRange
        ? this.getCreatedTimeRange("end")
        : null,
      workItemwithReports: this.searchCriteria?.workitemReports ? true : false,
    };

    // only filter schedule success on scheduled jobs
    if (this.workJobType.includes(WORKITEM_JOB_TYPE.scheduled)) {
      this.formInline = {
        ...this.formInline,
        ScheduledSuccess: SCHEDULE_STATUS.ScheduleSuccess,
      };
    }

    tsGraphQLService.getWorkItemList(this.formInline).subscribe(
      (res) => {
        let data = res.data;
        const unResolvedJobs: string[] = [];
        if (data?.listByPage?.length > 0) {
          data.listByPage.forEach((item, index) => {
            if (
              !item.schedule_type &&
              ![
                EXECUTION_STATUS.succeed,
                EXECUTION_STATUS.failed,
                EXECUTION_STATUS.submitFailed,
                EXECUTION_STATUS.aborted,
              ].includes(
                item?.cx_iops_agent_job_executions[0]?.execution_status || "NEW"
              )
            ) {
              unResolvedJobs.push(item.id);
            }

            if (item.schedule_type === SCHEDULE_TYPE.once) {
              this.$set(data.listByPage, index, {
                ...data.listByPage[index],
                children: [
                  {
                    created_time: item.created_time
                      ? moment(item.created_time).format(_format)
                      : "",
                    id: data.listByPage[index]?.id,
                    ...data.listByPage[index]?.cx_iops_agent_job_executions[0],
                  },
                ],
              });
            }

            this.$set(data.listByPage, index, {
              ...data.listByPage[index],
              created_time: item.created_time
                ? moment(item.created_time).format(_format)
                : "",
              owner: getExternalSystemNameForUser(item.owner),
            });
            if (item.hybris_workspace_item_jobs.length > 0) {
              this.$set(data.listByPage, index, {
                ...data.listByPage[index],
                content:
                  item.hybris_workspace_item_jobs[0].hybris_nb_cio_job.job_name,
                private: item.hybris_workspace_item_jobs[0].hybris_nb_cio_job
                  .is_private
                  ? "Private"
                  : "Public",
              });
            }

            if (item.cx_iops_agent_job_executions) {
              const {
                meta_data = null,
                execution_status = "NEW",
                execution_id = "",
              } = item.cx_iops_agent_job_executions.length > 0
                ? item.cx_iops_agent_job_executions[0]
                : {};
              this.$set(data.listByPage, index, {
                ...data.listByPage[index],
                execution_status: execution_status,
                execution_id: execution_id,
                meta_data: meta_data ? JSON.parse(meta_data) : meta_data,
              });
            }
          });

          this.getWebsocketData(unResolvedJobs);
          this.getWebsocketData2(unResolvedJobs);
          this.tableData = data.listByPage;
        } else {
          this.tableData = [];
        }

        this.total = data.listTotal.aggregate.count || 0;

        this.initFormTitle();
        this.loading = false;
      },
      (error) => {
        const status = error?.networkError?.statusCode;
        if (status === 401) {
          location.reload();
        }
      }
    );
  }

  /**
   * The initial filter condition is the value of the selection
   * @returns void
   */
  initFormTitle(): void {
    this.tableTitle.forEach((item: any, index: any) => {
      if (item.prop === "product") {
        //Checking CASL roles and setting SCV! or CCV!,CCV2
        this.$set(this.tableTitle, index, {
          ...item,
          filters: Auth.getProductFilters(),
        });
      }
      if (item.prop === "execution_status") {
        this.$set(this.tableTitle, index, {
          ...item,
          filters: [
            { text: "NEW", value: "NEW" },
            {
              text: EXECUTION_STATUS.submitFailed,
              value: EXECUTION_STATUS.submitFailed,
            },
            { text: EXECUTION_STATUS.running, value: EXECUTION_STATUS.running },
            { text: EXECUTION_STATUS.failed, value: EXECUTION_STATUS.failed },
            { text: EXECUTION_STATUS.succeed, value: EXECUTION_STATUS.succeed },
            {
              text: EXECUTION_STATUS.aborting,
              value: EXECUTION_STATUS.aborting,
            },
            { text: EXECUTION_STATUS.pending, value: EXECUTION_STATUS.pending },
            { text: EXECUTION_STATUS.aborted, value: EXECUTION_STATUS.aborted },
            {
              text: EXECUTION_STATUS.timedout,
              value: EXECUTION_STATUS.timedout,
            },
          ],
        });
      }
    });
  }

  /**
   * Set the filter parameter values and get the data
   * @params {filters}: filters values
   * @returns void
   */
  filterHandler(filters: Record<string, number | boolean | string>): void {
    // special case when filter product
    if (filters.product && JSON.stringify(filters.product) === "[]") {
      this.formInline = {
        ...this.formInline,
        product: Auth.getProductFilters().map((product) => product?.value),
      };
      return this.searchData();
    }
    if (filters && JSON.stringify(filters) !== "{}") {
      this.formInline = {
        ...this.formInline,
        ...filters,
      };
      this.searchData();
    }
  }

  /**
   * abort running job with execution_id
   */
  cancelRunningJob(executionId: string): void {
    this.cancelBtnObj = {
      ...this.cancelBtnObj,
      [executionId]: true,
    };
    tsService
      .cancelRunningJob(executionId)
      .then(() => {
        this.$message({
          message: "Canceling the running job started",
          type: "success",
          duration: 3000,
        });
      })
      .finally(() => {
        setTimeout(() => {
          this.getData();
        }, 0);
      });
  }

  /**
   * get websocket message by monitorig the signalR targets
   * @returns void
   */
  getWebsocketData(unResolvedJobs: string[]): void {
    signalRService.minitorSignalRTarget(
      unResolvedJobs,
      "noServiceId",
      "onJobStatus",
      async (data) => {
        console.log("receive data from signalR", data);
        const { executionId, status } =
          typeof data === "string" ? JSON.parse(data) : data;
        if (status === "ABORTED") {
          const workitemObj: SUBMIT_WORKITEM_OBJECT = {
            owner: this.$store.state.userId,
            created_by: this.$store.state.userId,
            summary: "cleanup_containerized_job",
            product: "CCV2",
            executor: "CCV2 Rundeck",
            meta_config: JSON.stringify({
              email: {
                notify: true,
                events: ["SUBMIT_FAILED", "FAILED"],
                emailAddress: "linzong.xiang@sap.com",
              },
            }),
            hybris_workspace_item_jobs: {
              data: { job_id: process.env.VUE_APP_CLEANCONTAINER_JOBID },
            },
            parameters: JSON.stringify([
              {
                _name: "CONSUMER_EMAIL",
                type: "input",
                value: "linzong.xiang@sap.com",
                label: "Consumer email",
                hidden: "true",
                _secure: "false",
                fileContent: "",
                description: "Consumer email",
                argumentSign: "",
              },
              {
                _name: "WORKITEM_ID",
                type: "input",
                value: "WORKITEM_ID",
                label: "Workitem id",
                hidden: "true",
                _secure: "false",
                fileContent: "",
                description: "Workitem id",
                argumentSign: "",
              },
              {
                _name: "EXECID",
                type: "input",
                value: `${executionId}`,
                label: "EXECID",
                hidden: "false",
                _secure: "false",
                fileContent: "",
                description: "",
                regex: "",
                argumentSign: "",
              },
            ]),
            app_name: this.$store.state.currentApp.name,
            job_type: WORKITEM_JOB_TYPE.ondemand,
            schedule_type: null as any,
          };

          await triggerJob(executionId, workitemObj);
        }

        this.searchData();
      }
    );
  }

  /**
   * get websoct message by monitoring websocket channel
   */
  getWebsocketData2(unResolvedJobs: string[]): void {
    signalRService.monitorWsChannel(
      unResolvedJobs,
      "UnKnown",
      "onJobStatus",
      (data) => {
        const { status } = typeof data === "string" ? JSON.parse(data) : data;
        if (status) this.searchData();
      }
    );
  }
}
