
import "@/assets/styles/ItemListPage.scss";
import { Component, Vue } from "vue-property-decorator";
import TableComponent from "@/shared/components/ItemListComponent/Table/Table.vue";
import SearchItem from "@/shared/components/SearchItem.vue";
import store from "@/store/index";
import { WORKITEM_JOB_TYPE } from "@/shared/interface/newItem";
import { SEARCH_ITEM_CRITERIA } from "@/shared/interface/searchCriteria";

@Component({
  name: "ItemListPage",
  components: {
    TableComponent,
    SearchItem,
  },
})
export default class ItemListPage extends Vue {
  currentApp: any = store.state.currentApp;
  activeTab: WORKITEM_JOB_TYPE = WORKITEM_JOB_TYPE.ondemand;
  tabs = [WORKITEM_JOB_TYPE.ondemand, WORKITEM_JOB_TYPE.scheduled];
  searchData: any;

  data(): { searchData: any } {
    return {
      searchData: undefined,
    };
  }

  created(): void {
    store.watch(
      (state, getters) => state.currentApp,
      (newValue, oldValue) => {
        this.currentApp = newValue;
        this.searchData = undefined;
      }
    );
  }

  updateSearchCriteria(formData: SEARCH_ITEM_CRITERIA): void {
    this.searchData = formData;
    this.$forceUpdate();
  }
}
